import { useDataProvider } from 'react-admin';

export function useModifyRole () {

    const dataProvider = useDataProvider();

    const modifyMappingTable = async (data, field, table, sourceField, destField) => {
        let existingMappings = await dataProvider.getList(table, {
            pagination: { page: 1 , perPage: 1000 },
            sort: { field: 'id', order: 'ASC' },
            filter: { [sourceField]: data.id }
        });
        let existingData = existingMappings.data.map(x => x[destField]);
        let createData = data[field].filter(value => !existingData.includes(value));
        let deleteIds = existingMappings.data.filter(value => !data[field].includes(value[destField])).map(x => x.id);
        await Promise.all(createData.map(newData => 
            dataProvider.create(table, {data: { [sourceField]: data.id, [destField]: newData }})
        ));
        await Promise.all(deleteIds.map(deleteId => 
            dataProvider.delete(table, { id: deleteId })
        ));
    }

    return async (data) => {
        const mappings = {
            permissionMapping: { field: 'permissionArray', table: 'role-has-permission', sourceField: 'role', destField: 'permission' },
        }    
        await Promise.all(Object.keys(mappings).map(x => 
            modifyMappingTable(
                data, 
                mappings[x].field, 
                mappings[x].table, 
                mappings[x].sourceField, 
                mappings[x].destField
            )
        ));
        Object.keys(mappings).forEach(x => delete data[mappings[x].field])
        return data;
    }
}